export const projects = [
  {
    name: "Windoor",
    stack: "ReactJS",
    description: "Institucional",
    demo: "https://www.windooraberturas.com/",
  },
  {
    name: "Elishar",
    stack: "ReactJS",
    description: "Mundanzas",
    demo: "https://www.elishar.com.ar/",
  },
  {
    name: "LauraBArte",
    stack: "ReactJS",
    description: "Arte",
    demo: "https://www.laurabarte.com.ar/",
  },
  {
    name: "Real Cars",
    stack: "ReactJs",
    description: "Alquiler de autos",
    demo: "https://realcars.vercel.app/",
  },
  {
    name: "Fauno",
    stack: "ReactJs",
    description: "Empresarial",
    demo: "https://fauno-arquitectura.vercel.app/",
  },
  {
    name: "NHQ Web W.I.P",
    stack: "ReactJS",
    description: "E-commerce",
    demo: "https://nohayquimica.vercel.app/",
  },
];
